import React from "react"
import { Link } from "gatsby"
import functionalityIcon from "../../assets/images/icons/functionalityIcon.png"
import familiarityIcon from "../../assets/images/icons/familiarityIcon.png"
import simplicityIcon from "../../assets/images/icons/simplicityIcon.png"
import { Parallax, ParallaxBanner } from "react-scroll-parallax"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faGamepad,
  faLaptop,
  faRavelry,
  faStar,
} from "@fortawesome/free-solid-svg-icons"
import useWindowDimensions from "../../hooks/useWindowDimensions"
import FadeInWhenVisible from "../FadeInView/FadeInView"
import Marquee from "react-fast-marquee"
import ReactWOW from "react-wow"

const OurSolutions = () => {
  const { height, width } = useWindowDimensions()
  const alignCenter = { display: "flex", alignItems: "center" }
  return (
    <section className="blog-area pt-100 ">
      <div className="container">
        <div className="section-title pb-1">
          <span className="sub-title " id="weAre">
            {/* <img src={starIcon} alt="star" /> */}
            <FontAwesomeIcon icon={faRavelry} />
            We Are
          </span>
          <ReactWOW delay=".1s" animation="zoomIn">
            <h2 className="banner-equation "> Breaking The Mould </h2>
          </ReactWOW>
          <p className="pt-4 mb-5">
            {/* <Link to="/service-details"> */}
            Mexxar is a robust consultancy and professional Software Development
            and IT services provider with innovative concepts and ideas that
            facilitate companies in diversified industries.
            {/* </Link> */}
          </p>

          <div className="row justify-content-around">
            <div className="col-md-4 my-3 ">
              <div className="d-flex flex-column align-items-center ">
                <ReactWOW delay=".3s" animation="bounceIn">
                  <FontAwesomeIcon icon={faStar} size="3x" />
                </ReactWOW>

                <ReactWOW delay=".4s" animation="bounceIn">
                  <label className="mt-3">Simplicity</label>
                  <p className="text-justify">
                    At Mexxar, we specialize in developing revolutionized
                    software-based products that enable companies to swiftly
                    respond to erratic market conditions by utilizing their
                    resources in a more agile manner.
                  </p>
                </ReactWOW>
              </div>
            </div>
            <div className="col-md-4  my-3">
              <div className="d-flex flex-column align-items-center ">
                <ReactWOW delay=".5s" animation="bounceIn">
                  <FontAwesomeIcon icon={faGamepad} size="3x" />
                </ReactWOW>

                <ReactWOW delay=".6s" animation="bounceIn">
                  <label className="mt-3">Familiarity</label>
                  <p className="text-justify">
                    Our solutions assure you functionality combined with
                    in-depth industry knowledge, improved business performance
                    and competitive advantage.
                  </p>
                </ReactWOW>
              </div>
            </div>{" "}
            <div className="col-md-4  my-3">
              <div className="d-flex flex-column align-items-center ">
                <ReactWOW delay=".6s" animation="bounceIn">
                  <FontAwesomeIcon icon={faLaptop} size="3x" />
                </ReactWOW>

                <ReactWOW delay=".7s" animation="bounceIn">
                  <label className="mt-3">Functionality</label>
                  <p className="text-justify">
                    Our outstanding expertise in software development empower us
                    to cater to your desired requirements from designing,
                    building, maintaining and modernizing the product to a
                    top-notch software.
                  </p>
                </ReactWOW>
              </div>
            </div>
          </div>

          {/* <div className="row ">
            <Parallax
              translateY={[-20, 10]}
              className="col-lg-6 col-md-12 d-flex flex-column justify-content-center  sff"
            >
              <h3
                className=" "
                id="weAre"
                style={{ fontSize: "60px", color: "#343a40" }}
              >
                Simplicity
              </h3>{" "}
            </Parallax>{" "}
            <Parallax className="col-lg-6 col-md-12 " translateY={[50, -50]}>
              <div className="single-services-box-item-we-are ">
                <img
                  src={simplicityIcon}
                  alt="star"
                  style={{ height: "200px" }}
                />{" "}
                <p className="text-left">
                  At Mexxar, we specialize in developing revolutionized
                  software-based products that enable companies to swiftly
                  respond to erratic market conditions by utilizing their
                  resources in a more agile manner.
                </p>
              </div>{" "}
            </Parallax>
          </div>
          <div className="row ">
            <Parallax
              className="col-lg-6 col-md-12 d-flex flex-column justify-content-center  "
              y={[20, -40]}
              tagOuter="figure"
            >
              <div className="single-services-box-item-we-are">
                <img
                  src={functionalityIcon}
                  alt="star"
                  style={{ height: "200px" }}
                />

                <p className="text-right">
                  Our solutions assure you functionality combined with in-depth
                  industry knowledge, improved business performance and
                  competitive advantage.
                </p>
              </div>
            </Parallax>{" "}
            <Parallax
              className="col-lg-6 col-md-12   d-flex flex-column justify-content-center "
              y={[0, 0]}
              tagOuter="figure"
            >
              <h3
                className=" "
                id="weAre"
                style={{ fontSize: "60px", color: "#343a40" }}
              >
                Functionality
              </h3>
            </Parallax>
          </div>
          <div className="row   " style={{ height: "450px" }}>
            <Parallax
              className="col-lg-6 col-md-12 d-flex flex-column justify-content-center  "
              y={[-60, 0]}
              tagOuter="figure"
            >
              <h3
                className=" "
                id="weAre"
                style={{ fontSize: "60px", color: "#343a40" }}
              >
                Familiarity
              </h3>
            </Parallax>{" "}
            <Parallax
              className="col-lg-6 col-md-12  "
              y={[0, -60]}
              tagOuter="figure"
            >
              <div className="single-services-box-item-we-are">
                <img
                  src={familiarityIcon}
                  alt="star"
                  style={{ height: "200px" }}
                />

                <p className="text-left">
                  Our outstanding expertise in software development and the
                  utilization of optimized project management methodologies
                  empower us to cater to your desired requirements from
                  designing, building, maintaining and modernizing the product
                  to a top-notch software.
                </p>
              </div>
            </Parallax>
          </div> */}

          {/* <div className="row ">
            <div className="col-lg-6 col-md-12   d-flex flex-column justify-content-center">
              <h3 className="" id="weAre" style={{ fontSize: "60px" }}>
                Familiarity
              </h3>
            </div>
            <div className="col-lg-6 col-md-12  ">
              <div className="single-services-box-item-we-are">
                <img
                  src={familiarityIcon}
                  alt="star"
                  style={{ height: "200px" }}
                />

                <p>
                  Our outstanding expertise in software development and the
                  utilization of optimized project management methodologies
                  empower us to cater to your desired requirements from
                  designing, building, maintaining and modernizing the product
                  to a top-notch software.
                </p>
              </div>
            </div>
          </div> */}
        </div>
      </div>

      {/* <Marquee className="marqueeComponent" gradient={false}>
        <div className="marqueeWord mr-2">CRM</div>
        <div className="marqueeWord2 mr-2">Enterprise Solutions </div>
        <div className="marqueeWord mr-2">Artifical Intelligence </div>
        <div className="marqueeWord2 mr-2">POS </div>
      </Marquee> */}
    </section>
  )
}

export default OurSolutions
