import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import mobileApp from "../../assets/images/MexxarCRM/mobileApp.png"
import monitor from "../../assets/images/MexxarCRM/monitor.png"
import reports from "../../assets/images//MexxarCRM/reports.png"
import payroll from "../../assets/images/MexxarCRM/payroll.png"
import Marquee from "react-fast-marquee"
import {
  motion,
  useViewportScroll,
  useMotionValue,
  useTransform,
  useSpring,
} from "framer-motion"
import { Parallax } from "react-scroll-parallax"

const RecentProjects = () => {
  const customVariants = {
    hover: {
      x: 1.1,
    },
  }

  const [currentTab, setCurrentTab] = useState("Dash")

  const switchTabHandler = tab => {
    setCurrentTab(tab)
  }

  const imageVariants = {
    initial: {
      opacity: 1,
      // x: "-200px",
      scale: 0.8,
    },
    in: {
      x: "0px",
      opacity: 1,
      transition: { duration: 1 },
      scale: 1,
    },
    out: {
      x: "200px",
      opacity: 0,
      scale: 0.8,
      transition: { duration: 1 },
    },
  }

  const [isComplete, setIsComplete] = useState(false)
  const { scrollYProgress } = useViewportScroll()
  const yRange = useTransform(scrollYProgress, [0, 0.9], [0, 1])
  // const pathLength = useSpring(yRange, { stiffness: 400, damping: 90 });

  useEffect(
    () => yRange.onChange(v => setIsComplete(v >= 1)),
    console.log("y", yRange),
    [yRange]
  )

  return (
    <section className="projects-area bg-color pt-100 pb-70">
      <div className="container">
        <div className="container">
          <div className="section-title">
            <span className="sub-title">
              <motion.path
                d="M 0, 20 a 20, 20 0 1,0 40,0 a 20, 20 0 1,0 -40,0"
                style={{ pathLength: scrollYProgress }}
              />
              Mexxar Recruitment Version 1.0
            </span>
            <h2 id="mexxarCRM" className="banner-equation">
              Presenting The Future Of CRM
            </h2>
            <p>
              At Mexxar, we offer customised CRM solutions tailored to
              accelerate your digital transformation. We future-proof businesses
              through reengineering processes, allowing you to surface insights,
              optimise talent, boost efficiency and dedicate more in your
              vision.
            </p>
          </div>

          <div className="row">
            <div className="col-lg-8 col-md-6">
              {currentTab === "Dash" && (
                // <Parallax
                // opacity={[0.5,1 ,"easeInOut"]}>
                <motion.img
                  initial="initial"
                  animate={currentTab == "Dash" ? "in" : "out"}
                  // exit="out"
                  variants={imageVariants}
                  src={monitor}
                  alt="monitor"
                />
                // </Parallax>
              )}
              {currentTab === "RG" && (
                <motion.img
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={imageVariants}
                  src={reports}
                  alt="reports"
                />
              )}
              {currentTab === "CandidateAPP" && (
                <motion.img
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={imageVariants}
                  src={mobileApp}
                  alt="mobileApp"
                />
              )}
              {currentTab === "payroll" && (
                <motion.img
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={imageVariants}
                  src={payroll}
                  alt="payroll"
                />
              )}
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="row">
                <div className=" justify-content-center align-items-center mt-5">
                  <div>
                    <h2 className="mb-4" style={{ color: "#1b1b1b" }}>
                      A Stress-Free CRM Solution
                    </h2>
                    {/* <img src={crmClient} alt="CRM" /> */}
                    <TabNavigationMenu
                      activated={currentTab}
                      onChangeItem={switchTabHandler}
                      itemList={[
                        { name: "Dashboard", key: "Dash" },
                        { name: "Reports", key: "RG" },
                        { name: "Payroll", key: "payroll" },
                        { name: "App", key: "CandidateAPP" },
                      ]}
                    ></TabNavigationMenu>
                    {currentTab === "Dash" && (
                      <div>
                        <p className="p-custom ">
                          Pre-built reminders, performance updates and other
                          supplementary features will save you time on
                          follow-up, streamline operations, facilitate
                          processes, and increase your profitability.
                        </p>
                      </div>
                    )}
                    {currentTab === "RG" && (
                      <div>
                        <p className="p-custom ">
                          For powerful reporting and ease in decision making
                          drill down and enjoy maximum insight of your results
                          with graphical metrics, conversion reports, advanced
                          analytics and more.
                        </p>
                      </div>
                    )}
                    {currentTab === "payroll" && (
                      <div>
                        <p className="p-custom ">
                          Easy to manage, accurate and worry-free payroll system
                          will offer you expert support by just a few clicks. It
                          simply streamlines your paydays.
                        </p>
                      </div>
                    )}
                    {currentTab === "CandidateAPP" && (
                      <div>
                        <p className="p-custom ">
                          Signature features provide you a seamless experience
                          to manage your documents, timesheets, availabilities,
                          bookings and payments; all with minimal effort from
                          the comfort of your home.
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="testimonials-view-btn text-center">
                    {/* <Link to="/testimonials" className="default-btn">
                      <i className="flaticon-web"></i>
                      Book Demo<span></span>
                    </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default RecentProjects

export const TabNavigationMenu = props => {
  const { activated, onChangeItem, itemList } = props
  return (
    <div className="d-flex  my-2 ">
      <div className="mb-4">
        <ul className="nav text-sm" role="tablist">
          {itemList.map(item => {
            return (
              <li
                className={`nav-item mr-2`}
                onClick={() => onChangeItem(item.key)}
                key={item.key}
              >
                <a
                  style={{ cursor: "pointer" }}
                  className={`nav-link-custom-1  p-2 ${
                    item.key === activated ? "active" : ""
                  }`}
                  // href="#"
                  data-toggle="tab"
                >
                  {item.name}
                </a>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}
