import React from "react"
import ReactWOW from "react-wow"
import { Link } from "gatsby"
import bannerImg from "../../assets/images/MexxarCRM/pc.png"
import { motion } from "framer-motion"
import Service3 from "../../assets/images/services/service-shape3.png"
import monitor from "../../assets/images/MexxarCRM/monitor.png"
import banner from "../../assets/images/background15.jpg"
// import Starts1 from "../../assets/images/shape/starts1.svg"
import Satellite from "../../assets/images/icons/satellite.svg"
import Satellite2 from "../../assets/images/icons/satellite2.png"
import useWindowDimensions from "../../hooks/useWindowDimensions"
import ParticleBackground from "./ParticleBackground"
import "./customCSS.css"
import { Parallax, ParallaxBanner, useParallax } from "react-scroll-parallax"

const Banner = () => {
  const { height, width } = useWindowDimensions()

  const svgVariants = {
    hidden: { x: 500, y: -200 },
    visible: {
      x: 10,
      y: 500,
      transition: { duration: 30, yoyo: Infinity },
    },

    hidden2: { x: 300, y: -20 },
    visible2: {
      x: 700,
      y: -200,
      transition: { duration: 30, yoyo: Infinity },
    },
  }

  const pathVariants = {
    hidden: {
      opacity: 0,
      pathLength: 0,
    },
    visible: {
      opacity: 1,
      pathLength: 1,
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  }

  const wHeight = height + 100

  return (
    <ParallaxBanner
      layers={[
        {
          image: banner,
          speed: -20,
        },
      ]}
      style={{
        backgroundSize: "1920px",
        aspectRatio: "0.8/1",
        backgroundPositionX: "300px",
        height: "720px",
      }}
    >
      <div
        className="banner d-flex justify-content-center flex-column "
        style={{ height: "700px" }}
      >
        <div className="container  mb-2 p-4">
          <div className="row ">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="d-flex  justify-content-center flex-column align-items-center ">
                <ReactWOW delay=".1s" animation="fadeInLeft">
                  <label className="banner-heading" id="main-banner-id">
                    Your digital future
                    <ReactWOW delay=".1s" animation="fadeInLeft">
                      <span className="banner-heading-two mx-3">Redefined</span>
                    </ReactWOW>
                  </label>
                </ReactWOW>

                <ReactWOW delay=".1s" animation="fadeInLeft">
                  <p
                    className="banner-tagline"
                    style={{
                      marginTop: "20px",
                      marginBottom: "45px",
                      fontWeight: "600",
                    }}
                  >
                    Reimagine your business with custom solutions
                  </p>
                </ReactWOW>

                <ReactWOW delay=".1s" animation="fadeInRight">
                  <div className="btn-box">
                    <Link to="/contact" className="default-btn">
                      {/* <i className="flaticon-right"></i> */}
                      Lets Talk <span></span>
                    </Link>
                  </div>
                </ReactWOW>
              </div>
            </div>

            {/* <div className="col-lg-6 col-md-12 ">
            <ReactWOW delay=".1s" animation="fadeInUp">
              <div className="main-banner-image ">
                <img src={bannerImg} alt="banner" />
              </div>
            </ReactWOW>
          </div> */}
          </div>
        </div>
      </div>
    </ParallaxBanner>
  )
}

export default Banner
