import React, { useState } from "react"
import { Link } from "gatsby"
import starIcon from "../../assets/images/star-icon.png"
// import client1 from "../../assets/images/testimonials/client1.jpg"
// import client2 from "../../assets/images/testimonials/client2.jpg"
// import client3 from "../../assets/images/testimonials/client3.jpg"
import shape from "../../assets/images/shape/shape1.svg"
import Loadable from "@loadable/component"

import react from "../../assets/images/technologiespng/react.png"
import android from "../../assets/images/technologiespng/android.png"
import angular from "../../assets/images/technologiespng/angular.png"
import aws from "../../assets/images/technologiespng/aws.png"
import firebase from "../../assets/images/technologiespng/firebase.png"
import flutter from "../../assets/images/technologiespng/flutter.png"
import gradle from "../../assets/images/technologiespng/gradle.png"
import html5 from "../../assets/images/technologiespng/html5.png"
import ios from "../../assets/images/technologiespng/ios.png"
import java from "../../assets/images/technologiespng/java.png"
import mysql from "../../assets/images/technologiespng/mysql.png"
import node from "../../assets/images/technologiespng/node.png"
import php from "../../assets/images/technologiespng/php.png"
import selenium from "../../assets/images/technologiespng/selenium.png"
import shopify from "../../assets/images/technologiespng/shopify.png"
import typescript from "../../assets/images/technologiespng/typescript.png"
import vue from "../../assets/images/technologiespng/vue.png"
import wordpress from "../../assets/images/technologiespng/wordpress.png"
import pyTorch from "../../assets/images/technologiespng/pyTorch.png"
import tensorFlow from "../../assets/images/technologiespng/tensorFlow.png"

const OwlCarousel = Loadable(() => import("react-owl-carousel3"))

const options = {
  loop: true,
  nav: true,
  dots: false,
  autoplayHoverPause: true,
  autoplay: true,
  margin: 30,
  navText: [
    "<i class='flaticon-left-1'></i>",
    "<i class='flaticon-right-1'></i>",
  ],
  responsive: {
    0: {
      items: 1,
    },
    768: {
      items: 2,
    },
    992: {
      items: 2,
    },
  },
}

const Testimonials = () => {
  const [display, setDisplay] = React.useState(false)
  const [currentTab, setCurrentTab] = useState("frontEnd")

  const switchTabHandler = tab => {
    setCurrentTab(tab)
  }

  React.useEffect(() => {
    setDisplay(true)
  }, [])

  return (
    <div className="section-title technologies">
      <div className="col-lg-12 col-md-12">
        <span className="sub-title " id="weAre">
          Ready To Deliver
        </span>
        <h2 className="mb-5 ">Technologies We Work With</h2>
        <TabNavigationMenu
          activated={currentTab}
          onChangeItem={switchTabHandler}
          itemList={[
            { name: "Frontend", key: "frontEnd" },
            { name: "Database", key: "database" },
            { name: "Mobile", key: "mobile" },
            { name: "Backend", key: "backEnd" },
            { name: "Infra & Devops", key: "infraAndDevop" },
            { name: "CMS", key: "cms" },
            { name: "Machine Learning", key: "ML" },
          ]}
        ></TabNavigationMenu>

        {currentTab === "frontEnd" && (
          <div className="row teach-lable-container">
            <div className="col-4 col-sm-4 col-md-3 col-lg-2 m-4">
              <a className=" teach-lable-sub-container">
                <img className="teach-lable-logo" src={react} alt="react" />
                <label>React JS</label>
              </a>
            </div>
            <div className="col-4 col-sm-4 col-md-3 col-lg-2 m-4">
              <a className="teach-lable-sub-container">
                <img className="teach-lable-logo" src={angular} alt="angular" />
                <label>Angular JS</label>
              </a>
            </div>
            <div className="col-4 col-sm-4 col-md-3 col-lg-2 m-4">
              <a className="teach-lable-sub-container">
                <img className="teach-lable-logo" src={vue} alt="vue" />
                <label>Vue JS</label>
              </a>
            </div>
            <div className="col-4 col-sm-4 col-md-3 col-lg-2 m-4">
              <a className="teach-lable-sub-container">
                <img
                  className="teach-lable-logo"
                  src={typescript}
                  alt="typescript"
                />
                <label>TypeScript</label>
              </a>
            </div>
            <div className="col-4 col-sm-4 col-md-3 col-lg-2 m-4">
              <a className="teach-lable-sub-container">
                <img className="teach-lable-logo" src={flutter} alt="flutter" />
                <label>Flutter</label>
              </a>
            </div>
          </div>
        )}

        {currentTab === "database" && (
          <div className="row teach-lable-container">
            <div className="col-4 col-sm-4 col-md-3 col-lg-2 m-4">
              <a className=" teach-lable-sub-container">
                <img className="teach-lable-logo" src={mysql} alt="mysql" />
                <label>MySQL</label>
              </a>
            </div>
            <div className="col-4 col-sm-4 col-md-3 col-lg-2 m-4">
              <a className="teach-lable-sub-container">
                <img
                  className="teach-lable-logo"
                  src={firebase}
                  alt="firebase"
                />
                <label>Firebase</label>
              </a>
            </div>
          </div>
        )}
        {currentTab === "mobile" && (
          <div className="row teach-lable-container">
            <div className="col-4 col-sm-4 col-md-3 col-lg-2 m-4">
              <a className=" teach-lable-sub-container">
                <img className="teach-lable-logo" src={ios} alt="ios" />
                <label>iOS</label>
              </a>
            </div>
            <div className="col-4 col-sm-4 col-md-3 col-lg-2 m-4">
              <a className="teach-lable-sub-container">
                <img className="teach-lable-logo" src={android} alt="android" />
                <label>Android</label>
              </a>
            </div>
            <div className="col-4 col-sm-4 col-md-3 col-lg-2 m-4">
              <a className="teach-lable-sub-container">
                <img className="teach-lable-logo" src={flutter} alt="flutter" />
                <label>Flutter</label>
              </a>
            </div>
            <div className="col-4 col-sm-4 col-md-3 col-lg-2 m-4">
              <a className="teach-lable-sub-container">
                <img
                  className="teach-lable-logo"
                  src={react}
                  alt="react-native"
                />
                <label>React Native</label>
              </a>
            </div>
          </div>
        )}
        {currentTab === "backEnd" && (
          <div className="row teach-lable-container">
            <div className="col-4 col-sm-4 col-md-3 col-lg-2 m-4">
              <a className=" teach-lable-sub-container">
                <img className="teach-lable-logo" src={java} alt="java" />
                <label>Java</label>
              </a>
            </div>
            <div className="col-4 col-sm-4 col-md-3 col-lg-2 m-4">
              <a className=" teach-lable-sub-container">
                <img className="teach-lable-logo" src={php} alt="php" />
                <label>PHP</label>
              </a>
            </div>
            <div className="col-4 col-sm-4 col-md-3 col-lg-2 m-4">
              <a className="teach-lable-sub-container">
                <img className="teach-lable-logo" src={node} alt="node" />
                <label>node JS</label>
              </a>
            </div>
          </div>
        )}
        {currentTab === "infraAndDevop" && (
          <div className="row teach-lable-container">
            <div className="col-4 col-sm-4 col-md-3 col-lg-2 m-4">
              <a className=" teach-lable-sub-container">
                <img
                  className="teach-lable-logo"
                  src={aws}
                  alt="aws"
                  style={{ height: "40px", marginTop: "30px" }}
                />
                <label style={{ marginTop: "20px" }}>AWS</label>
              </a>
            </div>
            {/* <div className="col-4 col-sm-4 col-md-3 col-lg-2 m-4">
              <a className="teach-lable-sub-container">
                <img className="teach-lable-logo" src={gradle} alt="gradle" />
                <label>Gradle</label>
              </a>
            </div> */}
            <div className="col-4 col-sm-4 col-md-3 col-lg-2 m-4">
              <a className="teach-lable-sub-container">
                <img
                  className="teach-lable-logo"
                  src={selenium}
                  alt="selenium"
                />
                <label>Selenium</label>
              </a>
            </div>
          </div>
        )}
        {currentTab === "cms" && (
          <div className="row teach-lable-container">
            <div className="col-4 col-sm-4 col-md-3 col-lg-2 m-4">
              <a className=" teach-lable-sub-container">
                <img
                  className="teach-lable-logo"
                  src={wordpress}
                  alt="wordpress"
                />
                <label>Wordpress</label>
              </a>
            </div>
            <div className="col-4 col-sm-4 col-md-3 col-lg-2 m-4">
              <a className="teach-lable-sub-container">
                <img className="teach-lable-logo" src={shopify} alt="shopify" />
                <label>Shopify</label>
              </a>
            </div>
          </div>
        )}
        {currentTab === "ML" && (
          <div className="row teach-lable-container">
            <div className="col-4 col-sm-4 col-md-3 col-lg-2 m-4">
              <a className=" teach-lable-sub-container">
                <img className="teach-lable-logo" src={pyTorch} alt="pyTorch" />
                <label>PyTorch</label>
              </a>
            </div>
            <div className="col-4 col-sm-4 col-md-3 col-lg-2 m-4">
              <a className="teach-lable-sub-container">
                <img
                  className="teach-lable-logo"
                  src={tensorFlow}
                  alt="tensorFlow"
                />
                <label>TensorFlow</label>
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
    // <div className="testimonials-area pt-100 bg-f1f8fb">
    //     <div className="container">
    //         <div className="section-title">
    //             <span className="sub-title">
    //                 <img src={starIcon} alt="testimonial" />
    //                 Testimonials
    //             </span>
    //             <h2>What Our Clients are Saying?</h2>
    //             <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
    //         </div>

    //         {display ? <OwlCarousel
    //             className="testimonials-slides owl-carousel owl-theme"
    //             {...options}
    //         >
    //             <div className="single-testimonials-item">
    //                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna ali. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

    //                 <div className="client-info">
    //                     <div className="d-flex justify-content-center align-items-center">
    //                         <img src={client1} alt="testimonial" />
    //                         <div className="title">
    //                             <h3>Alex Maxwell</h3>
    //                             <span>CEO at EnvyTheme</span>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>

    //             <div className="single-testimonials-item">
    //                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna ali. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

    //                 <div className="client-info">
    //                     <div className="d-flex justify-content-center align-items-center">
    //                         <img src={client2} alt="testimonial" />
    //                         <div className="title">
    //                             <h3>David Warner</h3>
    //                             <span>CEO at Envato</span>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>

    //             <div className="single-testimonials-item">
    //                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna ali. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
    //                 <div className="client-info">
    //                     <div className="d-flex justify-content-center align-items-center">
    //                         <img src={client3} alt="testimonial" />
    //                         <div className="title">
    //                             <h3>Sarah Taylor</h3>
    //                             <span>CEO at ThemeForest</span>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </OwlCarousel> : ''}

    //         <div className="testimonials-view-btn text-center">
    //             <Link to="/testimonials" className="default-btn">
    //                 <i className="flaticon-view"></i>
    //                 Check Out All Reviews <span></span>
    //             </Link>
    //         </div>
    //     </div>

    //     <div className="shape-img1">
    //         <img src={shape} alt="testimonial" />
    //     </div>
    // </div>
  )
}

export default Testimonials

export const TabNavigationMenu = props => {
  const { activated, onChangeItem, itemList } = props
  return (
    <div className="d-flex my-2 justify-content-center">
      <div className="mb-2 ">
        <ul className="nav text-sm" role="tablist">
          {itemList.map(item => {
            return (
              <li
                className={`nav-item`}
                onClick={() => onChangeItem(item.key)}
                key={item.key}
              >
                <a
                  style={{ cursor: "pointer" }}
                  className={`nav-link-custom-1  ${
                    item.key === activated ? "active" : ""
                  }`}
                  // href="#"
                  data-toggle="tab"
                >
                  {item.name}
                </a>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}
