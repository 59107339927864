import React from "react"
import { Link } from "gatsby"
// import mission2 from "../../assets/images/our-mission/our-mission2.png"
import starIcon from "../../assets/images/star-icon.png"
const OurServices = () => {
  return (
    <div className="our-mission-area ptb-50">
      <div className="container-fluid">
        {/* <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="our-mission-content">
                            <div className="content">
                                <h2 id="weDo">Digitalized your world with us</h2>
                                <p className="p-custom">Our pool of multiskilled innovators design and develop custom software products that help you elevate your service efficiency. We listen, learn and understand the most intricate challenges. Each idea you provide are considered unique throughout the development process – from concept definition to testing, migrating, deployment and maintenance. We develop the most flexible models that would fit your goals, resources and timeline – we value your efficacy as much as our own.</p>
                                <ul className="features-list">
                                    <li><i className="flaticon-tick"></i> Software Development and Maintenance</li>
                                    <li><i className="flaticon-tick"></i> IT Infrastructure with Cloud Solutions</li>
                                    <li><i className="flaticon-tick"></i> Business Intelligence and Data Management</li>
                                    <li><i className="flaticon-tick"></i> Mobile Application Development</li>
                                    <li><i className="flaticon-tick"></i> Information Security and Client Privacy</li>
                                    <li><i className="flaticon-tick"></i> QA Consulting and Testing</li>
                                    <li><i className="flaticon-tick"></i> Business Process Automation and Outsourcing</li>
                                    <li><i className="flaticon-tick"></i> Research and Development</li>
                                    <li><i className="flaticon-tick"></i> Mobile Application Development</li>
                                    <li><i className="flaticon-tick"></i> Tech Support Help Desk</li>
                                    <li><i className="flaticon-tick"></i> HR and Payroll Consulting</li>
                                    <li><i className="flaticon-tick"></i> Training and Development</li>
                                </ul>
                                <Link to="/membership-levels" className="default-btn">
                                    <i className="flaticon-right"></i> 
                                    Contact Now <span></span>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="our-mission-image">
                            
                           
                        </div>
                    </div>
                </div> */}

        {/* <div className="row text-align-center bg-info">
         Software Development and Maintenance </div>
        <div className="row align-items-center">
          <div className="col-lg-4 col-md-12">2A</div>
          <div className="col-lg-4 col-md-12">2B</div>
          <div className="col-lg-4 col-md-12">2C</div>
        </div>
        <div className="row align-items-end">
          <div className="col-lg-4 col-md-12">3A</div>
          <div className="col-lg-4 col-md-12">3B</div>
          <div className="col-lg-4 col-md-12">3C</div>
        </div>
        <div className="row align-items-start">
          <div className="col-lg-4 col-md-12">4A</div>
          <div className="col-lg-4 col-md-12">4B</div>
          <div className="col-lg-4 col-md-12">4C</div>
        </div>
        <div className="row align-items-center">B</div> */}
      </div>
    </div>
  )
}

export default OurServices
