import React from "react"
import { Link } from "gatsby"
// import projectStart from "../../assets/images/gallery/gallery3.jpg"

import projectStart from "../../assets/images/background14.jpg"
 
const ProjectStartArea = () => {
  return (
    <div className="project-start-area ptb-100">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="project-start-image">
              <img src={projectStart} alt="project" />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="project-start-content">
              <h2>We'd Like to Work With You</h2>
              <p className="p-custom">
                We offer the most simple and convenient business process to all
                our stakeholders. Our clients recive the straightforward and
                tailor-made solutions to meet the ever-changing demands.
              </p>

              <Link to="/contact" className="default-btn">
                {/* <i className="flaticon-web"></i> */}
                Get Started
                <span></span>
              </Link>
            </div>
          </div>
        </div>
      </div>

       
    </div>
  )
}

export default ProjectStartArea
