import React from "react"
import { Link } from "gatsby"
// import project2 from "../../assets/images/projects/project2.jpg"
import softwareDevelopment from "../../assets/images/icons/software-development.png"
import testing from "../../assets/images/icons/testing.png"
import research from "../../assets/images/icons/research.png"
import automation from "../../assets/images/icons/automation.svg"
import cyberSecurity from "../../assets/images/icons/cyber-security.png"
import dataManagement from "../../assets/images/icons/data-management.png"
import girlInVr from "../../assets/images/girlinvr2.png"
import mexxarLogo from "../../assets/images/girlinvr.png"

import b6 from "../../assets/images/background6.jpg"
import b7 from "../../assets/images/background7.jpg"
import b13 from "../../assets/images/background13.jpg"
import mainBanner2 from "../../assets/images/mainBanner2.jpg"
import background14 from "../../assets/images/background14.jpg"
import sampleVideo from "../../assets/videos/video2.mp4"
import "./customCSS.css"
// import ServiceIcon7 from "../../assets/images/services/service-icon7.png"
// import ServiceIcon8 from "../../assets/images/services/service-icon8.png"
// import ServiceIcon9 from "../../assets/images/services/service-icon9.png"
// import ServiceIcon10 from "../../assets/images/services/service-icon10.png"
// import ServiceIcon11 from "../../assets/images/services/service-icon11.png"
// import ServiceIcon12 from "../../assets/images/services/service-icon12.png"
// import ServiceShape4 from "../../assets/images/services/service-shape4.png"
import { motion } from "framer-motion"
import { Parallax, ParallaxBanner, useParallax } from "react-scroll-parallax"
import ScrollContainer from "../Common/ScrollContainer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBug,
  faChartLine,
  faCogs,
  faGamepad,
  faLaptop,
  faLaptopCode,
  faMicroscope,
  faRavelry,
  faStar,
  faUserShield,
} from "@fortawesome/free-solid-svg-icons"
import { Container } from "../Common/Container"
import ReactWOW from "react-wow"

const OurFeatures = () => {
  const customVariants = {
    hover: {
      scale: 1.1,
    },
  }

  return (
    // <ParallaxBanner
    //   layers={[
    //     {
    //       image: girlInVr,
    //       speed: -20,

    //       // opacity: [0, 1, "easeInOut"],
    //     },

    //     {
    //       children: <Body />,
    //       speed: 20,
    //       // opacity: 0,
    //     },
    //   ]}
    //   startScroll={-100}
    //   endScroll={100}
    //   shouldAlwaysCompleteAnimation={true}
    //   style={{
    //     // background: "#eb0101",
    //     // aspectRatio: "3 / 1",
    //     height: "700px",
    //   }}
    // ></ParallaxBanner>
    //

    <Body />
  )
}

export default OurFeatures

function Body() {
  return (
    <section className="time-to-discover">
      <ParallaxBanner
        layers={[
          {
            image: girlInVr,
            speed: -10,
            opacity: [0.8, 1, "easeInOut"],
          },
          {
            children: (
              <div className="container" style={{ height: "fit-content" }}>
                <div className="  d-felx text-align-center   ">
                  {/* <Parallax
              opacity={[0.8, 1]}
              translateY={[-170, 120]}
              style={{ position: "absolute", height: "350px" }}
            >
              <img src={girlInVr} alt="girlInVr" />{" "}
            </Parallax> */}
                  <div className="d-felx flex-column  align-items-center justify-content-center">
                    <span className="mt-5" id="weAre">
                      Time to discover
                    </span>

                    <Parallax scale={[2, 0.8, "easeOut"]}>
                      <label
                        id="ourServices"
                        className=" mb-0 time-to-discover-header"
                      >
                        Services
                      </label>
                    </Parallax>
                  </div>
                  {/* <Parallax
                opacity={[0.7, 1, "easeInOut"]}
                translateY={[15, -20]}
                style={{ marginTop: "200px" }}
              > */}
                  <p
                    style={{
                      color: "white",
                      marginTop: "50px",
                      fontSize: "15px",
                      fontWeight: "600",
                    }}
                  >
                    Our pool of multiskilled innovators design and develop
                    custom software products to elevate your service efficiency.
                    Each idea you provide is considered unique throughout the
                    development process – from concept definition to testing,
                    migrating, deployment and maintenance. We develop the most
                    flexible models that would fit your goals, resources and
                    timeline – we value your efficacy as much as our own.
                  </p>
                  {/* <p>
                    <video
                      autoPlay
                      loop
                      playsInline
                      preload="auto"
                      className="embed-responsive embed-responsive-16by9 "
                      controls
                    >
                      <source
                        className="embed-responsive-item"
                        src={sampleVideo}
                        type="video/mp4"
                      ></source>
                    </video>
                  </p> */}
                  {/* </Parallax> */}
                </div>
              </div>
            ),
          },
        ]}
        style={{
          background: "#eb0101",
          backgroundImage: "linear-gradient(#9e8281, #eb0101)",
          aspectRatio: "1 / 1",
          height: "500px",
        }}
      ></ParallaxBanner>

      <div class="container mt-5 service-area-body ">
        <div className="row  ">
          <ReactWOW delay=".3s" animation="fadeInLeft">
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div style={{ width: "320px" }}>
                <div className="orange-collar">
                  <FontAwesomeIcon
                    icon={faLaptopCode}
                    color="#fff"
                    className=" fa-2x"
                    style={{ margin: "45px 0px 0px -30px" }}
                  />
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center service-box ">
                  <label>Software Development and Maintenance</label>
                  <p>
                    We blend talent, technology and tools together with your
                    ideas and our expertise to create customised software
                    solutions to optimise your industry’s performance.
                  </p>
                </div>
              </div>
            </div>
          </ReactWOW>
          <ReactWOW delay=".3s" animation="fadeInDown">
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div style={{ width: "320px" }}>
                <div className="orange-collar">
                  {" "}
                  <FontAwesomeIcon
                    icon={faCogs}
                    color="#fff"
                    className=" fa-2x"
                    style={{ margin: "45px 0px 0px -30px" }}
                  />
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center service-box ">
                  <label>Business Process Automation and Outsourcing</label>
                  <p>
                    We unleash automation, allowing enterprises to empower
                    workforce, drive creative thinking, enhance relationships
                    and experirence. Our outsourcing services create synergy
                    between your corporate strategy and execution.
                  </p>
                </div>
              </div>
            </div>
          </ReactWOW>
          <ReactWOW delay=".3s" animation="fadeInRight">
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div style={{ width: "320px" }}>
                <div className="orange-collar">
                  {" "}
                  <FontAwesomeIcon
                    icon={faMicroscope}
                    color="#fff"
                    className=" fa-2x"
                    style={{ margin: "45px 0px 0px -30px" }}
                  />
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center service-box ">
                  <label>Research and Development</label>
                  <p>
                    We explore emerging technologies and cutting-edge solutions
                    to create products that enrich people’s daily lives. Our
                    culture of innovation is created through people, powered by
                    technology.
                  </p>
                </div>
              </div>
            </div>
          </ReactWOW>
        </div>

        <div className="row">
          <ReactWOW delay=".3s" animation="fadeInLeft">
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div style={{ width: "320px" }}>
                <div className="orange-collar">
                  {" "}
                  <FontAwesomeIcon
                    icon={faUserShield}
                    color="#fff"
                    className=" fa-2x"
                    style={{ margin: "45px 0px 0px -30px" }}
                  />
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center service-box ">
                  <label>Information Security and Client Privacy</label>
                  <p>
                    We continue to enhance our approach to Information Security
                    and Client Privacy with modern security controls and tools
                    to ensure that client data are protected.
                  </p>
                </div>
              </div>
            </div>
          </ReactWOW>
          <ReactWOW delay=".3s" animation="fadeInUp">
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div style={{ width: "320px" }}>
                <div className="orange-collar">
                  {" "}
                  <FontAwesomeIcon
                    icon={faChartLine}
                    color="#fff"
                    className=" fa-2x"
                    style={{ margin: "45px 0px 0px -30px" }}
                  />
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center service-box ">
                  <label>Business Intelligence and Data Management</label>
                  <p>
                    From improving efficiency to ensuring competitiveness and
                    profitability; we provide the most simplified metrics to
                    help you keep track of crucial details without losing sight
                    of the bigger picture.
                  </p>
                </div>
              </div>
            </div>
          </ReactWOW>
          <ReactWOW delay=".3s" animation="fadeInRight">
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div style={{ width: "320px" }}>
                <div className="orange-collar">
                  <FontAwesomeIcon
                    icon={faBug}
                    color="#fff"
                    className=" fa-2x"
                    style={{ margin: "45px 0px 0px -30px" }}
                  />
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center service-box ">
                  <label>Quality Assurance Consulting and Testing</label>
                  <p>
                    Our passion and dedication ensure that we offer end-to-end
                    tools and techniques to reduce costs, increase efficiency
                    and achieve your digital transformation objectives.
                  </p>
                </div>
              </div>
            </div>
          </ReactWOW>
        </div>
      </div>
    </section>
  )
}
