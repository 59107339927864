import React from "react"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import Banner from "../components/Index/Banner"
import OurSolutions from "../components/Index/OurSolutions"
import OurServices from "../components/Index/OurServices"
import OurFeatures from "../components/Index/OurFeatures"
import TeamMember from "../components/Index/TeamMember"
import RecentProjects from "../components/Index/RecentProjects"
import Pricing from "../components/Index/Pricing"
import Testimonials from "../components/Index/Testimonials"
import Partner from "../components/Index/Partner"
import ProjectStartArea from "../components/Index/ProjectStartArea"
import OurBlog from "../components/Index/OurBlog"
import Helmet from "react-helmet"
import Contact from "./contact"
import { ParallaxProvider } from 'react-scroll-parallax';

const Home = () => {
  return (
    <ParallaxProvider>
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Mexxar</title>
          <link rel="mexxar" href="http://mexxar.com" />
          <meta
            name="description"
            content="Mexxar is a robust consultancy and professional software development and IT services provider. At Mexxar, we specialize in developing revolutionized and innovative software-based products for different companies and applications in diversified industries."
          />
          <meta name="author" content="Mexxar(Pvt)Ltd" />
          <meta
            name="keywords"
            content="BPO Software,UK,United Kingdom,Mobile App development,CRM ,Recruitment CRM, Company, Sri, Lanka, Outsource, Healthcare, Medical, Solutions"
          />
          <meta property="og:title" content="Mexxar" />
          <meta property="og:image" content="your_image_url" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@nytimes" />
          <meta name="twitter:creator" content="@SarahMaslinNir" />
          <meta name="twitter:title" content="" />
          <meta
            name="twitter:description"
            content="Mexxar is a robust consultancy and professional software development and IT services provider. "
          />
          <meta
            name="twitter:image"
            content="http://graphics8.nytimes.com/images/2012/02/19/us/19whitney-span/19whitney-span-articleLarge.jpg"
          />
        </Helmet>
        <Navbar />
        <Banner />
        <OurSolutions />
        <OurServices />
        <Partner />
        <OurFeatures />
        <TeamMember />
        <RecentProjects />
        <Pricing />
        <Testimonials />
        
        <OurBlog />
        <ProjectStartArea />
        <Footer />
      </Layout>
    </ParallaxProvider>
  )
}

export default Home
